/* eslint-disable no-unused-expressions */
// const truncate = (input) => (input.length > 40 ? `${input.substring(0, 40)}...` : input);
import { Box } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

    const Logout = () => {
        if (typeof window !== "undefined") {
        setTimeout(() => {
          window.location.replace('/');
        }, 1000);
        localStorage.removeItem('ceflix.user')|| '';
        localStorage.removeItem('ceflix.encID')|| '';
        localStorage.removeItem('ceflix.purchase_token')|| '';
        localStorage.removeItem('ceflix.token')|| '';
        localStorage.removeItem('ceflix.subscriptions')|| '';
    };
};


const truncate = (val, num) => {
    if (val !== undefined && val !== null) {
    if (val.length > num) {
      return `${val.substring(0, num)}...`
    } else {
      return val;
    }
  }
  }

  const setTypeFunc = (e, func1, func2) => {
    func1(e.title);
    func2(e);
  };

  const duration = (seconds) => {
    const val = new Date(seconds * 1000).toISOString();
    if(val.substring(12, 13) === '0'){
      return val.substring(14, 19);
    } else {
      return val.substring(12, 19);
    }
  }

  const timeSince = (date) => {
      var seconds = Math.floor((+new Date() - (date * 1000)) / 1000);
    
      var interval = seconds / 31536000;
    
      if (interval > 1) {
        return Math.floor(interval) > 1 ? Math.floor(interval) + ' years ago' : Math.floor(interval) + ' year ago';
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) > 1 ? Math.floor(interval) + ' months ago' : Math.floor(interval) + ' month ago';
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) > 1 ? Math.floor(interval) + ' days ago' : Math.floor(interval) + ' day ago';
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) > 1 ? Math.floor(interval) + ' hours ago' : Math.floor(interval) + ' hour ago';
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) > 1 ? Math.floor(interval) + ' minutes ago' : Math.floor(interval) + ' minute ago';
      }
      return Math.floor(seconds) > 1 ? Math.floor(seconds) + ' seconds ago' : Math.floor(seconds) + ' second ago';
    }

    const abbreviateViews = (val) => {
      return Intl.NumberFormat('en-US', {   notation: "compact",   maximumFractionDigits: 1 }).format(val);
    }
    
    function capitalize(string) {
      if (typeof string === 'string') {
        const str_arr = string.split(' ');
    
        for (let i = 0; i < str_arr.length; i++) {
          str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
        }
        return str_arr.join(' ');
      } else {
        return string;
      }
    }    

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  

    export{
        Logout,
        truncate,
        duration,
        timeSince,
        abbreviateViews,
        setTypeFunc,
        capitalize,
        LinearProgressWithLabel,
    }
